<template>
  <div
    class="w-full h-full flex flex-col justify-center items-center"
    style="background: center / cover no-repeat url('/img/wallpaper_4.png')"
  >
    <!-- <h2 class="uppercase font-semibold text-9xl flex">
      4
      <span class="pl-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-28 w-28"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
          />
        </svg>
      </span>
      4
    </h2> -->
    <div class="relative flex justify-center h-full mt-10">
      <img
        src="img/awp_glamour_cover.png"
        alt="AWP Glamout Cover"
        class="h-full"
      />
      <p
        class="text-red-500 absolute text-6xl tracking-widest ml-3"
        style="top: 31.5%"
      >
        404
      </p>
      <h2
        class="uppercase font-bold text-4xl absolute text-center tracking-wide"
        style="top: 43%; width: min-content"
      >
        Pagina inesistente
      </h2>
      <h2 class="absolute" style="width: max-content; bottom: 10%">
        Spiacenti, questa pagina non esiste o é stata rimossa.
      </h2>
    </div>
  </div>
</template>

<script>
export default {
  name: "PageNotFound",

  mounted() {},
};
</script>

<style lang="postcss" scoped>
</style>
