var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "w-full h-full flex flex-col justify-center items-center",
        staticStyle: {
          background: "center / cover no-repeat url('/img/wallpaper_4.png')",
        },
      },
      [
        _c(
          "div",
          { staticClass: "relative flex justify-center h-full mt-10" },
          [
            _c("img", {
              staticClass: "h-full",
              attrs: {
                src: "img/awp_glamour_cover.png",
                alt: "AWP Glamout Cover",
              },
            }),
            _c(
              "p",
              {
                staticClass:
                  "text-red-500 absolute text-6xl tracking-widest ml-3",
                staticStyle: { top: "31.5%" },
              },
              [_vm._v(" 404 ")]
            ),
            _c(
              "h2",
              {
                staticClass:
                  "uppercase font-bold text-4xl absolute text-center tracking-wide",
                staticStyle: { top: "43%", width: "min-content" },
              },
              [_vm._v(" Pagina inesistente ")]
            ),
            _c(
              "h2",
              {
                staticClass: "absolute",
                staticStyle: { width: "max-content", bottom: "10%" },
              },
              [
                _vm._v(
                  " Spiacenti, questa pagina non esiste o é stata rimossa. "
                ),
              ]
            ),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }